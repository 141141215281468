import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "./Images/hollasollogo.png";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const footerStyle = {
    backgroundImage:
      "url('https://villamiproperties.com/wp-content/uploads/2017/10/footer-background-2.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "white",
    position: "relative",
  };

  const overlayStyle = {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.1)",
    zIndex: -1,
  };

  return (
    <div>
      <footer className="bg-dark text-light mt-5 pt-3" style={footerStyle}>
        <div style={overlayStyle}></div>
        <Container className="py-2">
          <Row className="justify-content-center">
            <Col
              md={4}
              className="mb-4 text-center d-flex flex-column align-items-center"
            >
              <img
                className="fooer-logo  mb-3 rounded"
                alt="Holla's Innovations Logo"
                src={Logo}
                style={{
                  height: "75px",
                  width: "150px !important",
                  background: "white",
                }}
              />

              <p>
                Innovative, expert-driven digital solutions crafting brand
                success in the dynamic landscape, redefining excellence with
                impactful creativity.
              </p>
            </Col>

            <Col md={4} className="text-white text-center mb-4">
              <h5>Quick Links</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="/about" className="text-white text-decoration-none">
                    About
                  </a>
                </li>
                <li>
                  <a href="/blog" className="text-white text-decoration-none">
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href="/privacypolicy"
                    className="text-white text-decoration-none"
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    href="/contact"
                    className="text-white text-decoration-none"
                  >
                    Contact
                  </a>
                </li>
              </ul>
              {/* Social Media Icons */}
              <div className="social-icons mt-4 d-flex justify-content-center">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon mx-2"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="2x"
                    className="text-white"
                  />
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon mx-2"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="2x"
                    className="text-white"
                  />
                </a>
                <a
                  href="https://www.instagram.com/hollas_innovations/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon mx-2"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="text-white"
                  />
                </a>
                <a
                  href="https://in.linkedin.com/company/holla-s-innovations"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon mx-2"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    size="2x"
                    className="text-white"
                  />
                </a>
              </div>
            </Col>

            <Col md={4} className=" text-center">
              <h5>Contact Us</h5>
              <address>
                <p>
                Holla's Innovations Private Limited,<br></br> 312/A, 2nd Floor, 1st Main Rd, 8th Block, Jayanagar, Bengaluru, Karnataka 560070

                </p>
                <p className="text-light">
                  Email:{" "}
                  <a
                    href="mailto:info@hollasinnovations.com"
                    className="text-light text-decoration-none"
                  >
                    info@hollasinnovations.com
                  </a>
                </p>
                <p className="text-light">
                  Phone:{" "}
                  <a
                    href="tel:+916363008186"
                    className="text-light text-decoration-none"
                  >
                    +91 - 6363 008 186
                  </a>
                </p>
              </address>
            </Col>
          </Row>
          <p className="text-center">
            Pioneering the Future of Innovation <br></br> &copy; 2024 Holla's
            Innovations Private Limited. All rights reserved.
          </p>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
