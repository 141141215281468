import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import CustomNavbar from "./navbar";
import EmailForm from "./emailform";
import Footer from "./footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <CustomNavbar />
      <div className="container text-center py-4">
        <h1>Connect with Excellence</h1>
      </div>

      <Container className="my-1 py-1">
        <Row className="justify-content-center mt-2">
          {/* Left Column - Contact Information */}
          <Col md={6} className="d-flex">
            <Card className="flex-fill shadow p-4">
              <Card.Body className="text-center">
                <h4>Open Communication Channels</h4>
                <p className="lead">
                  Transform your brand into a captivating narrative with our
                  expertise. Reach out for tailored solutions, unlocking a
                  journey of digital success and unparalleled accomplishments.
                  Let's redefine your brand's identity and set forth on a path
                  of innovation and triumph in the dynamic digital landscape.
                </p>
                <div className="contact-icons mt-3">
                  <p className="mb-4">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="mr-2 text-black"
                    />
                    <a
                      href="tel:+916363008186"
                      className="text-black text-decoration-none"
                    >
                      +91 - 6363 008 186
                    </a>
                  </p>
                  <p className="mb-4">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="mr-2 text-black"
                    />
                    <a
                      href="mailto:info@hollasinnovations.com"
                      className="text-black text-decoration-none"
                    >
                      info@hollasinnovations.com
                    </a>
                  </p>

                  <p>
                    <FontAwesomeIcon icon={faMapMarker} className="mr-2" />
                    Holla's Innovations Private Limited, 312/A, 2nd Floor, 1st Main Rd, 8th Block, Jayanagar, Bengaluru, Karnataka 560070
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Right Column - Google Map */}
          <Col md={6} className="d-flex">
            <Card className="flex-fill shadow p-4">
              <Card.Body>
                <h4 className="text-center">Visit Us</h4>

                {/* Replace the following iframe with your Google Maps embed code */}
                <div className="google-map">
                  <iframe
                    title="Hollasol Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8124420480276!2d77.57358887418735!3d12.919773216014244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x64876ba0165dfbcb%3A0x94260a6ba62ec49a!2sHolla&#39;s%20Innovations!5e0!3m2!1sen!2sin!4v1719932431931!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <EmailForm />
      <Footer />
    </div>
  );
};

export default Contact;
