import React, { useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Footer from "./footer";
import CustomNavbar from "./navbar";
import { useLocation } from "react-router-dom";

const Careers = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const jobOpenings = [
    {
      title: "Frontend Developer",
      description: `
      We are seeking a motivated and skilled Frontend Developer to join our team. In this role, you will be responsible for developing and maintaining websites and web applications, focusing on creating user-friendly and visually appealing interfaces using modern web technologies.
        
      Location : Bengaluru
       
      Responsibilities:
      -Develop and implement responsive user interfaces using HTML, CSS, JavaScript, and modern frameworks such as React and Redux.
      -Collaborate with UX/UI designers to translate designs into high-quality code.
      -Optimize web applications for maximum speed and performance.
      -Work closely with backend developers to integrate APIs and ensure seamless functionality.
      -Participate in code reviews and contribute to best practices.

      Requirements:
      -Proven experience with HTML, CSS, JavaScript, and frameworks like React and Redux.
      -Strong problem-solving skills and attention to detail.
      -Excellent communication and teamwork skills.
      -Ability to work independently and as part of a team.

      To apply, please send your resume to info@hollasinnovations.com.
      `,
    },
    {
      title: "Backend Engineer",
      description: `
      We are looking for a dedicated and experienced Backend Engineer to join our team. The ideal candidate will have a strong background in server-side development and database management, and will be responsible for building and maintaining the backend infrastructure for our web applications.
        
      Location : Bengaluru
        
      Responsibilities:
      -Design, develop, and maintain server-side applications and APIs.
      -Work with databases to design and optimize data structures and queries.
      -Implement security and data protection measures.
      -Collaborate with frontend developers to integrate user-facing elements with server-side logic.
      -Troubleshoot and resolve issues in the backend systems.

      Requirements:
      -Proven experience with server-side languages and frameworks such as Python/Django or Node.js.
      -Strong knowledge of database management systems, including SQL and NoSQL.
      -Experience with cloud services and deployment strategies.
      -Excellent problem-solving skills and attention to detail.

      To apply, please send your resume to info@hollasinnovations.com.
      `,
    },
    {
      title: "UX Designer",
      description: `
      We are seeking a creative and detail-oriented UX Designer to enhance our user experience. In this role, you will design intuitive and engaging interfaces, conduct user research, and collaborate with the development team to implement design solutions.
        
      Location : Bengaluru / Udupi
       
      Responsibilities:
      -Create wireframes, prototypes, and high-fidelity designs for web applications.
      -Conduct user research and usability testing to gather insights and improve design.
      -Collaborate with product managers and developers to ensure designs meet user needs and business goals.
      -Develop and maintain design guidelines and best practices.
      -Stay updated on industry trends and best practices.

      Requirements:
      -Proven experience with UX design tools such as Sketch, Figma, or Adobe XD.
      -Strong portfolio showcasing your design work and problem-solving skills.
      -Experience in conducting user research and usability testing.
      -Excellent communication and collaboration skills.
      -A solid understanding of front-end technologies is preferred..
      -Experience with HTML, CSS, JavaScript is a plus.

      To apply, please send your resume to info@hollasinnovations.com.
      `,
    },
    {
      title: "Digital Marketing Expert",
      description: `
      We are looking for an experienced Digital Marketing Expert to join our team and drive our online marketing efforts. The ideal candidate will have a strong background in digital marketing strategies and execution, with a focus on increasing brand visibility and driving user engagement.
        
      Location : Bengaluru / Udupi
       
      Responsibilities:
      -Develop and execute digital marketing strategies across various channels including SEO, SEM, social media, and email marketing.
      -Analyze and report on the performance of digital marketing campaigns and optimize for better results.
      -Manage and coordinate content creation for marketing materials and campaigns.
      -Collaborate with the team to align marketing efforts with overall business goals.

      Requirements:
      -Proven experience in digital marketing, with expertise in SEO, SEM, social media, and email marketing.
      -Strong analytical skills and experience with marketing analytics tools.
      -Excellent communication and organizational skills.
      -Ability to manage multiple campaigns and projects simultaneously.
      
      To apply, please send your resume to info@hollasinnovations.com.
      `,
    },
  ];

  return (
    <>
      <CustomNavbar></CustomNavbar>
      <Container className="pt-3">
        <div className="careerss Holla's Innovations py-3">
          <h2> Careers </h2>
        </div>
        <h2>Careers at Holla's Innovations</h2>
        <p>
          Join our team and embark on a journey of innovation and growth. At
          Holla's Innovations, we are always looking for talented individuals who
          are passionate about technology and eager to make a difference.
        </p>

        <Row>
          {jobOpenings.map((job, index) => (
            <Col key={index} md={6} className="mb-4">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>{job.title}</Card.Title>
                  <Card.Text style={{ whiteSpace: "pre-wrap" }}>
                    {job.description}
                  </Card.Text>
                  <Button
                    className="bttn"
                    href="mailto:info@hollasinnovations.com?subject=Application"
                  >
                    Apply Now
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer></Footer>
    </>
  );
};

export default Careers;
