import React from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoImage from "./Images/hollasollogo.png";
import "./App.css";

const CustomNavbar = () => {
  return (
    <Navbar className="custom-navbar" fixed="top" variant="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/" className="custom-navbar-brand">
          <img
            src={logoImage}
            alt="Holla's Innovations Logo"
            className="custom-logo"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-3" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto align-items-center d-flex">
            <Nav.Link as={Link} to="/" className="mx-3">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="mx-3">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/detailedservices" className="mx-3">
              Services
            </Nav.Link>
            <Nav.Link as={Link} to="/careers" className="mx-3">
              Careers
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className="mx-3">
              <Button className="bttn">Contact</Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
