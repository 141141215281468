import React, { useEffect, useState } from "react";

const Counter = ({ value, label, plusSymbol }) => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => (prevCount < value ? prevCount + 1 : value));
    }, 50);

    return () => clearInterval(interval);
  }, [value]);

  return (
    <div className="col-md-3">
      <div className="counter text-center">
        <h2 className="font-weight-bold">{plusSymbol ? `${count}+` : count}</h2>
        <p>{label}</p>
      </div>
    </div>
  );
};

export default Counter;
