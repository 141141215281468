import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

const Services = () => {
  const servicesData = [
    {
      title: "Digital Experience Design",
      icon: require("./Images/websolutions.svg").default,
      description:
        "Elevate your online presence with our advanced website and web application development services. We craft digital experiences that merge innovative design with robust functionality, ensuring your online platform is both engaging and effective. Our bespoke solutions are tailored to meet your specific business goals, providing seamless interactions and maximizing user engagement.",
      link: "https://hollasinnovations.com",
    },
    {
      title: "Comprehensive IT Solutions",
      icon: require("./Images/visual.svg").default,
      description:
        "Enhance your technology infrastructure with our all-encompassing IT solutions. From initial system design and deployment to ongoing technical support, we deliver end-to-end services that boost operational efficiency and drive business growth. Our approach integrates cutting-edge technologies to offer scalable and secure solutions, keeping you ahead in a fast-paced market.",
    },
    {
      title: "Strategic Digital Marketing",
      icon: require("./Images/applications.svg").default,
      description:
        "Amplify your brand’s digital footprint with our expert digital marketing services. We create targeted strategies that increase visibility, foster engagement, and drive conversions. Our expertise spans Search Engine Optimization, content creation, social media strategy, and more, designed to optimize your online presence and deliver tangible results.",
    },
    {
      title: "Reliable Hosting and Support",
      icon: require("./Images/branding.svg").default,
      description:
        "Keep your digital assets running smoothly with our dependable hosting and maintenance solutions. We offer secure, high-performance hosting paired with proactive maintenance to ensure your websites and applications remain operational and up-to-date. Our services minimize downtime and enhance performance, allowing you to concentrate on growing your business.",
    },
    {
      title: "Scalable Cloud Solutions",
      icon: require("./Images/photography.svg").default,
      description:
        "Leverage the power of cloud technology with our flexible and scalable cloud services. We provide comprehensive cloud infrastructure, storage, and computing solutions that streamline resource management and support growth. Our cloud offerings are designed to improve accessibility, security, and collaboration, driving innovation and efficiency.",
    },
    {
      title: "Expert IT Consulting",
      icon: require("./Images/digital.svg").default,
      description:
        "Navigate the technology landscape with confidence through our professional IT consulting services. We offer strategic guidance and actionable insights to help you make informed decisions, implement effective technology solutions, and achieve your business objectives. Our consultancy services encompass technology assessments, digital transformation strategies, and more.",
    },
  ];
  return (
    <div>
      <Container className="py-3">
        <h2 className="text-center">Our Comprehensive Offerings</h2>
        <h6 className="text-center">
          Diverse Solutions Tailored for Your Success
        </h6>
        <Container className="mt-4 py-1">
          <Row>
            {servicesData.slice(0, 3).map((service, index) => (
              <Col className="detailedService" key={index} md={4}>
                <Card className="h-100 service-cards">
                  <div className="hover_color_bubble-1"></div>

                  <Card.Body>
                    <div className="text-center mb-3">
                      <img
                        src={service.icon}
                        alt={`Holla's Innovations Icon for ${service.title}`}
                        height={150}
                      />
                    </div>
                    <Card.Title className="service-title text-center">
                      {service.title}
                    </Card.Title>
                    <Card.Text className="service-description">
                      {service.description}
                    </Card.Text>
                    <a
                      href={service.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="mt-4 py-1">
            {servicesData.slice(3, 6).map((service, index) => (
              <Col key={index} md={4} className="detailedService">
                <Card className="service-cards h-100 py-3">
                  <div className="hover_color_bubble-2"></div>
                  <Card.Body>
                    <div className="text-center mb-3">
                      <img
                        src={service.icon}
                        alt={`Holla's Innovations Icon for ${service.title}`}
                        height={150}
                      />
                    </div>
                    <Card.Title className="text-center">
                      {service.title}
                    </Card.Title>
                    <Card.Text>{service.description}</Card.Text>
                    <a
                      href={service.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Services;
