import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";

const StyledCard = styled(Card)`
  height: 100%;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s;

  &:hover {
    @media (min-width: 768px) {
      transform: scale(1.05);
    }
    // background-color: ;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  img {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 1rem; /* Add a small bottom margin in mobile view */
  }
`;

const FeatureCard = ({ iconSrc, title, description }) => {
  return (
    <Col xs={12} md={6} lg={3} className="d-flex feature-container">
      <StyledCard className="flex-fill shadow">
        <div className="hover-feature"></div>

        <Card.Body>
          <img src={iconSrc} alt={title} height={60} />
          <Card.Title className="h5">{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
      </StyledCard>
    </Col>
  );
};

const FeaturesSection = () => {
  const features = [
    {
      title: "Precision and Excellence",
      iconSrc: require("./Images/craftsmanship.svg").default,
      description:
        "Elevate your brand with meticulous design, development, and branding, ensuring every digital touchpoint reflects unmatched precision and excellence.",
    },
    {
      title: "Cutting-edge Creativity",
      iconSrc: require("./Images/innovation.svg").default,
      description:
        "Our commitment to innovation and creativity transforms digital landscapes, delivering solutions that stand out in today's dynamic and competitive market.",
    },
    {
      title: "Adaptable Solutions",
      iconSrc: require("./Images/versatility.svg").default,
      description:
        "Experience a diverse range of services tailored to your brand's needs, fostering adaptability in design, development, and visual communication strategies.",
    },
    {
      title: "Integrated Distinction",
      iconSrc: require("./Images/excellence.svg").default,
      description:
        "Achieve a seamless fusion of creativity, functionality, and visual allure, setting your brand apart in web design, development, and visual storytelling.",
    },
  ];

  return (
    <Row>
      {features.map((feature, index) => (
        <FeatureCard key={index} {...feature} />
      ))}
    </Row>
  );
};

export default FeaturesSection;
