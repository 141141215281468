import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Client = () => {
  const clientLogos = [
    {
      id: 1,
      logoSrc: require("./Images/clients/Jnanashrunga.png"),
      link: "",
    },
    {
      id: 2,
      logoSrc: require("./Images/clients/Bgsckm.jpg"),
      link: "",
    },
    {
      id: 3,
      logoSrc: require("./Images/clients/Futureflow.png"),
      link: "",
    },
    {
      id: 4,
      logoSrc: require("./Images/clients/Skstudios.png"),
      link: "",
    },
    {
      id: 5,
      logoSrc: require("./Images/clients/Rsoit.png"),
      link: "",
    },
  ];

  const settings = {
    infinite: true,
    speed: 1250,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const logoHeight = "100px";
  const maxLogoWidth = "100%";

  return (
    <div className="container py-3">
      <h3 className="text-center">Our Clients</h3>
      <Container className=" pt-3"></Container>
      <Slider {...settings}>
        {clientLogos.map((client, index) => (
          <div key={index} className="client-item">
            <img
              className="d-block mx-auto"
              src={client.logoSrc}
              alt={`Holla's Innovations Client ${client.id}`}
              style={{ height: logoHeight, maxWidth: maxLogoWidth }}
              href={client.link}
            />{" "}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Client;
