import React, { useEffect } from "react";
import CustomNavbar from "./navbar";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "./footer";
import BackToTopButton from "./backtotop";
import { useLocation } from "react-router-dom";

const About = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <CustomNavbar></CustomNavbar>
      <Container fluid className="p-0 position-relative">
        <img
          src={require("./Images/Aboutcover.jpg")}
          alt="Holla'ssolutionsaboutCover"
          className="w-100"
          style={{ height: "400px" }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <h1 className="text-white">We fuse creativity with precision.</h1>
        </div>
      </Container>

      <Container className="my-3">
        <Row className="align-items-center">
          <Col md={6} className="py-5">
            <h2 className="font-weight-bold mb-4 text-dark">
              Unleashing Digital Excellence: Our Inspirational Journey
            </h2>
            <p className="text-muted">
              Embark on a narrative of innovation and creativity as our story
              unfolds—a journey of digital excellence where every project
              defines distinction.
            </p>
            <p className="text-muted">
              In the genesis of our story, a vision took root – a vision to
              redefine digital landscapes with unparalleled creativity and
              innovation. As our journey gracefully unfolded, every milestone
              became a testament to our unwavering commitment to excellence.
              From pioneering designs to cutting-edge development, each chapter
              elegantly showcases the fusion of art and technology. Our story
              transcends mere chronicles; it stands as a testament to the
              relentless pursuit of crafting digital experiences that surpass
              expectations. Today, we proudly stand as architects of a narrative
              where every pixel, every line of code contributes to a legacy of
              transformative online journeys, setting new standards in the
              digital realm.
            </p>
            <p className="text-muted">
              Rooted in passion, our story continues to evolve, driven by a
              shared commitment to pushing boundaries and delivering solutions
              that stand as paragons of digital brilliance. It's a story of
              growth, resilience, and an unwavering focus on our clients'
              success. With each project, we strive not merely to meet
              expectations but to surpass them, creating a narrative where
              brands not only thrive but leave an indelible mark in the digital
              landscape. Join us as we script the next chapter in our story – a
              chapter where creativity knows no bounds and every digital
              touchpoint becomes an opportunity for extraordinary impact and
              distinction.
            </p>
          </Col>

          <Col md={6} className="text-center">
            <img
              src={require("./Images/Ourstory.png")}
              alt="Holla's Innovations Our Story"
              className="img-fluid rounded"
              style={{ maxHeight: "500px" }}
            />
          </Col>
        </Row>
      </Container>

      <Container className="text-center">
        <h5 className="text-muted">
          Guided by an unwavering commitment to excellence, our featuring goal
          is to redefine industry benchmarks through innovative solutions. We
          strive to seamlessly blend cutting-edge technology with artistic
          brilliance, crafting digital experiences that transcend expectations
          and leave an indelible mark in the evolving landscape.
        </h5>
      </Container>

      <Container className="py-5 bg-light shadow rounded">
        <h2 className="text-center font-weight-bold mb-4 text-dark">
          Embark on a Transformative Journey with Us
        </h2>
        <p className="text-center text-muted">
          Where creativity converges with innovation to redefine digital
          landscapes. We are architects of unforgettable online experiences,
          seamlessly blending cutting-edge technology with artistic ingenuity.
          Our passion lies in crafting digital narratives that resonate with
          distinction, pushing boundaries in design, development, and beyond.
          Join us in shaping a future where every digital touchpoint becomes a
          testament to excellence.
        </p>
        <Row className="py-4">
          <Col md={4} className="d-flex detailedService">
            <Card className="flex-fill bg-light shadow">
              <Card.Body className="d-flex flex-column align-items-center">
                <i className="fa fa-5x fa-rocket text-primary mb-3" />

                <Card.Title className="text-center">Mission</Card.Title>
                <Card.Text className="text-center">
                  Empower businesses with cutting-edge technology and artistic
                  ingenuity, fostering a unique digital environment where
                  creativity and innovation thrive seamlessly.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="d-flex detailedService">
            <Card className="flex-fill bg-light shadow">
              <Card.Body className="d-flex flex-column align-items-center">
                <i className="fa fa-5x fa-solid fa-eye text-info mb-3" />

                <Card.Title className="text-center">Vision</Card.Title>
                <Card.Text className="text-center">
                  Pioneer transformative digital experiences, setting new
                  standards of creativity and innovation for lasting impact in
                  the evolving digital landscape.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="d-flex detailedService">
            <Card className="flex-fill bg-light shadow">
              <Card.Body className="d-flex flex-column align-items-center">
                <i className="fa fa-5x fa-solid fa-star text-warning mb-3" />
                <Card.Title className="text-center">Values</Card.Title>
                <Card.Text className="text-center">
                  Innovation, Excellence, Collaboration, Integrity,
                  Adaptability, Client-Centric Focus, and Passion for
                  Craftsmanship drive our commitment to creative and
                  technological brilliance.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="my-5 py-1">
        <Container className="py-1">
          <h2 className="text-center section-title">
            Elevate your digital presence with us – where design meets
            functionality, innovation thrives, and excellence is non-negotiable.
          </h2>
          <h5 className="text-center section-subtitle text-muted">
            We blend creativity and technology seamlessly, setting industry
            standards with visionary designs and robust development solutions.
          </h5>
        </Container>
        <Row className="mt-3">
          {/* Service Cards */}
          <Col md={4} className="mb-4">
            <Card className="flex-fill shadow rounded service-cards">
              <div className="hover_color_bubble_about"></div>
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <img
                  src={require("./Images/innovativedesign.svg").default}
                  alt="Holla's Innovations Innovative Design"
                  width="350"
                  height="100"
                  className="mb-3"
                />
                <Card.Title className="text-center">
                  Innovative Design
                </Card.Title>
                <Card.Text className="text-center">
                  Crafting intuitive and visually appealing forms that
                  streamline user interactions, elevate aesthetics, and enhance
                  data collection efficiency.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className=" service-cards">
              <div className="hover_color_bubble_about"></div>
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <img
                  src={require("./Images/strategicdevelopment.svg").default}
                  alt="Holla's Innovations Strategic Development Solutions"
                  width="350"
                  height="100"
                  className="mb-3"
                />
                <Card.Title className="text-center">
                  Strategic Development Solutions
                </Card.Title>
                <Card.Text className="text-center">
                  Engineering tailored solutions that go beyond conventional,
                  delivering innovative features and functionalities for
                  enhanced user experiences.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="flex-fill shadow rounded service-cards">
              <div className="hover_color_bubble_about"></div>
              <Card.Body className="d-flex flex-column align-items-center justify-content-centers">
                <img
                  src={require("./Images/clientmanagement.svg").default}
                  alt="Holla's Innovations Client-Centric Management"
                  width="350"
                  height="100"
                  className="mb-3"
                />
                <Card.Title className="text-center">
                  Client-Centric Management
                </Card.Title>
                <Card.Text className="text-center">
                  Dedicated client management ensuring transparent
                  communication, timely updates, and personalized support for
                  seamless collaboration and satisfaction.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4} className="mb-4">
            <Card className="flex-fill shadow rounded service-cards">
              <div className="hover_color_bubble_about"></div>
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <img
                  src={require("./Images/maintanance.svg").default}
                  alt="Holla's Innovations Proactive Maintenance Approach"
                  width="350"
                  height="100"
                  className="mb-3"
                />
                <Card.Title className="text-center">
                  Proactive Maintenance Approach
                </Card.Title>
                <Card.Text className="text-center">
                  Implementing proactive maintenance strategies, ensuring
                  optimal performance, security, longevity, and sustainability
                  for digital assets.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="flex-fill shadow rounded service-cards">
              <div className="hover_color_bubble_about"></div>
              <Card.Body className="d-flex flex-column align-items-center justify-content-centers">
                <img
                  src={require("./Images/uniquedevelopment.svg").default}
                  alt="Holla's Innovations Unique Development Discoveries"
                  width="350"
                  height="100"
                  className="mb-3"
                />
                <Card.Title className="text-center">
                  Unique Development Discoveries
                </Card.Title>
                <Card.Text className="text-center">
                  Constantly exploring and implementing unique approaches in
                  development, delivering solutions that stand out in the
                  dynamic digital landscape.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="flex-fill shadow rounded service-cards">
              <div className="hover_color_bubble_about"></div>
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <img
                  src={require("./Images/getintouch.svg").default}
                  alt="Holla's Innovations Get in Touch"
                  width="350"
                  height="100"
                  className="mb-3"
                />
                <Card.Title className="text-center">Get in Touch</Card.Title>
                <Card.Text className="text-center">
                  Reach out to us for personalized solutions, transparent
                  communication, and a collaborative partnership in your digital
                  journey.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="text-center">
        <h2 className="mb-4">
          Ignite Your Digital Evolution: Crafting Success Stories Together!
        </h2>
        <p className="lead">
          Nestled within a rich tapestry of accolades, we proudly stand as an
          award-winning force in the IT industry. Our journey is a symphony of
          innovation, unwavering commitment to customer satisfaction, and
          relentless progress—a testament to excellence. As pioneers in the
          technology industry, we are ready to propel your business towards
          unprecedented success and growth. Together, let's seize the myriad
          opportunities presented by the digital age. Our state-of-the-art
          solutions, rooted in a legacy of achievements and expertise, are
          crafted to meet the evolving demands of the modern era.
        </p>
        <p className="lead">
          Join us on this transformative journey as we redefine the future,
          shaping a realm of boundless possibilities for your business. Embrace
          the power of partnership and innovation—where success stories come to
          life.
        </p>
      </Container>
      <BackToTopButton></BackToTopButton>
      <Footer></Footer>
    </div>
  );
};

export default About;
