import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./home";
import Services from "./services";
import Client from "./client";
import Footer from "./footer";
import EmailForm from "./emailform";
import About from "./about";
import Contact from "./contact";
import FeaturesSection from "./feature";
import DetailedServices from "./detailedservices";
import BackToTopButton from "./backtotop";
import WhyHollas from "./whyhollas";
import Counter from "./counter";
import Blog from "./blog";
import PrivacyPolicy from "./privacypolicy";
import Careers from "./careers";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="feature" element={<FeaturesSection />} />
        <Route path="home" element={<Home />} />
        <Route path="services" element={<Services />} />
        <Route path="client" element={<Client />} />
        <Route path="emailform" element={<EmailForm />} />
        <Route path="About" element={<About />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="whyhollas" element={<WhyHollas />} />
        <Route path="Detailedservices" element={<DetailedServices />} />
        <Route path="counter" element={<Counter />} />
        <Route path="blog" element={<Blog />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="careers" element={<Careers />} />
        <Route path="backtotop" element={<BackToTopButton />} />
        <Route path="footer" element={<Footer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
