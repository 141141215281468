import React from "react";
import { Container } from "react-bootstrap";
import Footer from "./footer";
import CustomNavbar from "./navbar";

const PrivacyPolicy = () => {
  return (
    <>
      <CustomNavbar></CustomNavbar>
      <Container className="Privacy Policy py-3">
        <div className="Holla's Innovations Privacy Policy py-3">
          <h1>Privacy Policy</h1>
        </div>
        <h2>Privacy Statement</h2>
        <p>
          At Holla's Innovations, we are committed to protecting your privacy.
          This Privacy Policy outlines how we collect, use, disclose, and
          safeguard your information when you visit our website
          <a href="https://hollasinnovations.com/">(https://hollasinnovations.com/)</a> and
          use our services. By accessing our website or using our services, you
          consent to the terms of this Privacy Policy.
        </p>

        <h4>Information We Collect</h4>
        <p>
          We collect personal information from you when you voluntarily provide
          it to us or when you use our services. This information may include:
        </p>
        <ul>
          <li>Name, email address, phone number</li>
          <li>Company name and job title</li>
          <li>Payment and billing information</li>
          <li>Demographic information such as location</li>
          <li>Information provided through communications with us</li>
        </ul>

        <h4>How We Use Your Information</h4>
        <p>
          We use the information we collect for various purposes, including:
        </p>
        <ul>
          <li>Providing and delivering services you request</li>
          <li>Communicating with you, including responding to inquiries</li>
          <li>Customizing and improving our services and offerings</li>
          <li>Marketing and promotional purposes with your consent</li>
          <li>Complying with legal obligations</li>
        </ul>

        <h4>Sharing Your Information</h4>
        <p>
          We do not sell your personal information to third parties. We may
          share your information with:
        </p>
        <ul>
          <li>Service providers who assist in providing our services</li>
          <li>Business partners with your consent</li>
          <li>Legal authorities as required by law or to protect our rights</li>
        </ul>

        <h4>Security of Your Information</h4>
        <p>
          We implement appropriate technical and organizational security
          measures to protect your personal information. However, no method of
          transmission over the internet or electronic storage is completely
          secure. We cannot guarantee absolute security.
        </p>

        <h4>Retention of Your Information</h4>
        <p>
          We retain your personal information only as long as necessary for the
          purposes outlined in this Privacy Policy or as required by law. When
          no longer needed, your information will be securely deleted or
          anonymized.
        </p>

        <h4>Your Rights and Choices</h4>
        <p>
          You have the right to access, update, or delete your personal
          information. You may also object to the processing of your information
          under certain circumstances. Please contact us to exercise these
          rights.
        </p>

        <h4>Changes to This Privacy Policy</h4>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page. We
          encourage you to review this Privacy Policy periodically for updates.
        </p>

        <h4>Contact Us</h4>
        <p>
          If you have questions about this Privacy Policy or our practices,
          please contact us at{" "}
          <a href="mailto:info@hollasinnovations.com">info@hollasinnovations.com</a>
        </p>
      </Container>
      <Footer></Footer>
    </>
  );
};

export default PrivacyPolicy;
