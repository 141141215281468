import React, { useEffect } from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import CustomNavbar from "./navbar";
import Footer from "./footer";
import BackToTopButton from "./backtotop";
import { useLocation } from "react-router-dom";

const DetailedServices = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const servicesData = [
    {
      title: "Web Solutions",
      image: require("./Images/web.jpg"),
      description:
        "Transform your digital presence with our cutting-edge website and web application development services. We blend innovative design with robust functionality to create immersive online experiences. Our solutions are tailored to meet your business needs, ensuring a seamless user experience and enhanced engagement with your target audience.",
      link: "https://hollasinnovations.com",
    },
    {
      title: "IT Solutions",
      image: require("./Images/visual.jpg"),
      description:
        "Optimize your IT infrastructure with our comprehensive IT solutions. From system design and implementation to ongoing support, we offer end-to-end services that enhance operational efficiency and drive business success. Our team leverages advanced technologies to provide solutions that are both scalable and secure, ensuring you stay ahead in a competitive market.",
      link: "https://hollasinnovations.com",
    },
    {
      title: "Digital Marketing",
      image: require("./Images/applications.jpg"),
      description:
        "Expand your brand’s reach with our strategic digital marketing services. We specialize in crafting targeted campaigns that boost visibility, engage audiences, and drive conversions. Our expertise includes SEO, social media management, content marketing, and more, all designed to elevate your online presence and achieve measurable results.",
      link: "https://hollasinnovations.com",
    },
    {
      title: "Hosting and Maintenance",
      image: require("./Images/branding.jpg"),
      description:
        "Ensure your website and applications run smoothly with our reliable hosting and maintenance services. We provide secure, high-performance hosting solutions coupled with proactive maintenance to keep your digital assets operational and up-to-date. Our support ensures minimal downtime and optimal performance, so you can focus on your core business.",
      link: "https://hollasinnovations.com",
    },
    {
      title: "Cloud Services",
      image: require("./Images/photography.jpg"),
      description:
        "Harness the power of the cloud with our scalable and flexible cloud solutions. We offer cloud infrastructure, storage, and computing services that enable you to manage resources efficiently and scale as needed. Our cloud solutions are designed to enhance accessibility, security, and collaboration, driving innovation and growth.",
      link: "https://hollasinnovations.com",
    },
    {
      title: "IT Consultancy",
      image: require("./Images/digital.jpg"),
      description:
        "Navigate the complexities of technology with our expert IT consultancy services. We provide strategic advice and insights to help you make informed decisions, implement effective solutions, and leverage technology to meet your business objectives. Our consultancy covers everything from technology assessments to digital transformation strategies.",
      link: "https://hollasinnovations.com",
    },
  ];

  return (
    <div>
      <CustomNavbar></CustomNavbar>
      <Container fluid className="p-0 position-relative">
        <img
          src={require("./Images/Aboutcover.jpg")}
          alt="Holla's Innovations Background"
          className="w-100"
          style={{ height: "400px" }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <h1 className="text-white">Harmonizing innovation with finesse.</h1>
        </div>
      </Container>
      <Container className="py-5">
        <div className="text-center mb-4">
          <h2 className="display-4 font-weight-bold">
            Diverse Solutions Tailored for Your Success{" "}
          </h2>
          <p className="lead">
            Start a transformative journey with Holla's Innovations' comprehensive
            offerings. From cutting-edge technology solutions to captivating
            visuals, we craft experiences that elevate and redefine success in
            the digital landscape.
          </p>
        </div>

        <Row>
          {servicesData.map((service, index) => (
            <Col key={index} md={6} className="mb-4 detailedService">
              <Card className=" service-cards h-100">
                <div className="hover_color_bubble_service"></div>
                <Card.Body className="d-flex flex-column align-items-center">
                  <div className="text-center mt-3">
                    <img
                      src={service.image}
                      alt={`Holla's Innovations Icon for ${service.title}`}
                      height={150}
                    />
                  </div>
                  <Card.Title className="text-center mt-2 mb-3 lead fw-bold">
                    {service.title}
                  </Card.Title>
                  <Card.Text className="text-center mb-3 lead">
                    {service.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className=" container text-center">
        <h4 className="display-4 font-weight-bold">
          Unlock the Potential of Your Brand with Our Innovative Solutions
        </h4>
        <p className="lead">
          Step into the future of digital success by connecting with us. Explore
          the possibilities and redefine your brand's online presence. Let's
          engage in a conversation about how our expertise can elevate your
          journey and drive unparalleled growth. Take the initiative, and let's
          shape success together.
        </p>
        <Button className="bttn" href="/contact">
          Reach Out to Us
        </Button>
      </div>
      <Footer></Footer>
      <BackToTopButton></BackToTopButton>
    </div>
  );
};

export default DetailedServices;
