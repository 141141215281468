import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Counter from "./counter";

const WhyHollas = () => {
  const sectionStyle = {
    color: "white",
    paddingTop: "50px",
    paddingBottom: "50px",
  };

  return (
    <div className=" text-dark py-5" style={sectionStyle}>
      <Container>
        <h2 className="text-center mb-4">Your Path to Digital Success</h2>
        <Row className="align-items-center">
          <Col md={6} className="text-center mb-4">
            <img
              src="https://img.etimg.com/thumb/msid-69901533,width-640,resizemode-4,imgsize-114837/about-the-company.jpg"
              alt="Holla's Innovations Digital Success"
              className="img-fluid rounded"
            />
          </Col>
          <Col md={6} className="mb-4">
            <p className="lead">
              Discover a partner in digital excellence dedicated to turning your
              aspirations into reality. Our unique approach revolves around a
              comprehensive understanding of your distinct challenges, enabling
              us to craft customized solutions that resonate with your audience.
            </p>
            <p className="lead">
              Experience the difference with our unwavering commitment to
              providing unparalleled expertise and craftsmanship. From
              conceptualization to execution, we prioritize innovation and
              precision to breathe life into your vision.
            </p>
            <p className="lead">
              Our team of dedicated professionals is passionate about creating a
              lasting impact, guiding you through the intricacies of the digital
              world with confidence. Choose us as your partner in success,
              sharing your commitment to achieving greatness through digital
              transformation.
            </p>
          </Col>
        </Row>
        <hr className="bg-light my-4" />
        <Row className="text-center">
          <Counter value={4} label="Current Clients" plusSymbol={true} />
          <Counter value={16} label="Completed Projects" plusSymbol={true} />
          <Counter value={11} label="Happy Customers" plusSymbol={true} />
          <Counter value={6} label="Services" plusSymbol={true} />
        </Row>
      </Container>
    </div>
  );
};

export default WhyHollas;
