import React, { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import CustomNavbar from "./navbar";
import { Container, Row, Col } from "react-bootstrap";
import Services from "./services";
import Client from "./client";
import Footer from "./footer";
import FeaturesSection from "./feature";
import BackToTopButton from "./backtotop";
import WhyHollas from "./whyhollas";
import { useLocation } from "react-router-dom";
import image1 from "./Images/Hollasolcover1.jpg";
import image2 from "./Images/Hollasolcover2.jpg";

const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <CustomNavbar></CustomNavbar>
      <Carousel fade>
        <Carousel.Item>
          <img
            className="w-100  carousel-img"
            alt="Holla's Innovations First slide"
            src={image1}
            // style={{ hight: "900px" }}
          />
          <Carousel.Caption className="d-flex flex-column align-items-center justify-content-end h-100">
            <div style={{ maxWidth: "900px" }}>
              <h1 className="Holla's Innovations Main display-1 text-white mb-md-4 animated zoomIn">
                Designing Tomorrow's Digital Landscape
              </h1>
              <h5 className="Holla's Innovations Subline text-white text-uppercase mb-3 animated slideInDown">
                Crafting visuals that resonate innovation, sculpting a digital
                canvas where your brand truly shines.
              </h5>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="w-100 carousel-img"
            src={image2}
            alt="Holla's Innovations Second slide"
            // style={{ height: "900px" }}
          />
          <Carousel.Caption className="d-flex flex-column align-items-center justify-content-end h-100">
            <div style={{ maxWidth: "900px" }}>
              <h1 className="Holla's Innovations Main display-1 text-white mb-md-4 animated zoomIn">
                Embark on a Journey of Innovation
              </h1>
              <h5 className="Holla's Innovations Subline text-white text-uppercase mb-3 animated slideInDown">
                Where every click unfolds creativity, navigating a trail of
                unparalleled digital experiences.
              </h5>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="container mt-2">
        <div className="container py-1">
          <h3 className="main-heading text-center">Elevate Your Experience</h3>
        </div>
        <FeaturesSection></FeaturesSection>
      </div>
      <Container className="mt-4 py-3">
        <Container className="py-3 text-center">
          <h3 className="text-dark font-weight-bold">
            Elevate Your Brand with Pioneering Creativity and Digital Excellence
          </h3>
        </Container>
        <Row className="align-items-center">
          <Col
            xs={12}
            md={6}
            className="text-dark d-flex flex-column justify-content-center align-items-center"
          >
            <h2 className="font-weight-bold mb-4 text-center">
              Pioneering Creative Excellence for Unforgettable Digital Journeys
            </h2>
            <p className="text-center">
              At the intersection of boundless creativity and cutting-edge
              technology, we proudly stand as the architects of digital
              transformation. Our team, driven by passion and expertise,
              specializes in the intricate artistry of website design that
              transcends mere aesthetics. We push boundaries in web application
              development, crafting seamless, user-centric experiences that
              redefine the online landscape. Additionally, our lens extends
              beyond the digital realm; through compelling photography, we
              freeze moments in time, telling stories that resonate with
              authenticity and emotion.{" "}
            </p>
            <p className="text-center">
              Our commitment goes beyond surface beauty; it embeds itself in the
              very essence of your brand identity. By seamlessly blending
              artistic flair with technical prowess, we weave a digital tapestry
              that narrates your brand's story. Each website becomes a canvas,
              applications transform into interactive experiences, and visuals
              imprint themselves in the memories of your audience, leaving an
              enduring mark of distinction and connection. Join us on a journey
              where creativity meets technology, and every digital touchpoint
              becomes a meaningful chapter in your brand's unique narrative.
            </p>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <img
              src="https://media.istockphoto.com/id/1333790734/photo/the-concept-is-a-blockchain-technology-with-a-diagram-of-chain-and-encrypted-blocks-double.webp?b=1&s=170667a&w=0&k=20&c=umfxZftXXuqeP0vHn_sRRdU20SBIUlqWKIdIlOm6tFg="
              alt="Holla's Innovations"
              className="img-fluid rounded"
              style={{ maxHeight: "400px", width: "100%" }}
            />
          </Col>
        </Row>
      </Container>
      <Services></Services>
      <WhyHollas></WhyHollas>
      <Client></Client>
      <BackToTopButton></BackToTopButton>

      <Footer></Footer>
    </div>
  );
};

export default Home;
