import React, { useState } from "react";
import { Container, Form, Button, Modal } from "react-bootstrap";

const EmailForm = () => {
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [showModal, setShowModal] = useState(false);

  const sendEmail = () => {
    const mailtoURI = `mailto:info@hollasinnovations.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(`From Name: ${name} Subject: ${content}`)}`;

    window.location.href = mailtoURI;

    setShowModal(true);

    setName("");
    setSubject("");
    setContent("");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container className="mt-5">
      <h2>Get in Touch Today</h2>
      <Form>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formSubject">
          <Form.Label>Subject:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formContent">
          <Form.Label>Content:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your message"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </Form.Group>

        <Button className="bttn" onClick={sendEmail}>
          Send Email
        </Button>
      </Form>

      {/* Success Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Accessing Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are required to send an email using your default mail client.
        </Modal.Body>
        <Modal.Footer>
          <Button className="bttn" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EmailForm;
